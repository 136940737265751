import React, { ReactNode, useCallback, useState } from 'react'

import notification from '../components/common/Notification/notification'
import { LyricsAssistantMode } from '../components/editor/LyricsEditor/Lyrics.constants'
import { usePlayerConfigState } from '../components/editor/hooks/usePlayerConfigState'
import { LyricsCreativity, LyricsMood } from '../utils/types'
import { useInternationalization } from './InternationalizationContext'

type FetchType = {
  suggestionHovered?: boolean
  aiModeActive?: boolean
  setAiModeActive: (a: boolean) => void
  assistantOpen?: boolean
  setAssistantOpen: (a: boolean) => void
  mode: LyricsAssistantMode
  setMode: (a: LyricsAssistantMode) => void
  notEnoughLines?: boolean
  creativity: LyricsCreativity
  setCreativity: (a: LyricsCreativity) => void
  mood?: LyricsMood
  setMood: (a?: LyricsMood) => void
  suggestionsData: { error?: string | number; suggestion?: string[] } | null
  setSuggestionsData: (a: { error?: string | number; suggestion?: string[] } | null) => void
  suggestionsLoading?: boolean
  fetchSuggestions: (
    songPart: string,
    params?: { localCreativity?: LyricsCreativity; localMood?: LyricsMood },
  ) => Promise<void>
}

export const LyricsContext = React.createContext<FetchType>({
  setAiModeActive: () => {},
  setAssistantOpen: () => {},
  mode: 'ai-lines',
  setMode: () => {},
  creativity: 'medium',
  setCreativity: () => {},
  setMood: () => {},
  suggestionsData: null,
  setSuggestionsData: () => {},
  fetchSuggestions: () => Promise.resolve(),
})

export const LyricsProvider = ({ children }: { children: ReactNode }) => {
  const { text } = useInternationalization()
  const { playerConfig } = usePlayerConfigState()
  const lines = playerConfig.lyrics?.lines || []
  const linesNum = lines.length
  const notEnoughLines = linesNum < 2 || (linesNum === 2 && lines[1].length < 15)
  const [aiModeActive, setAiModeActive] = useState(false)
  const [assistantOpen, setAssistantOpen] = useState(false)
  const [mode, setMode] = useState<LyricsAssistantMode>('ai-lines')
  const [suggestionsData, setSuggestionsData] = useState<{
    error?: string | number
    suggestion?: string[]
  } | null>(null)
  const [suggestionsLoading, setSuggestionsLoading] = useState(false)
  const [mood, setMood] = useState<LyricsMood | undefined>()
  const [creativity, setCreativity] = useState<LyricsCreativity>('medium')

  const fetchSuggestions = useCallback(
    async (songPart: string, params?: { localCreativity?: LyricsCreativity; localMood?: LyricsMood }) => {
      const { localCreativity, localMood } = params || {}
      setSuggestionsLoading(true)
      notification.error(text.sidebars.lyrics.aiLyrics.error)
      // const data = await getSongPartSuggestionInnerRoute(
      //   lines,
      //   suggestionsData?.suggestion || [],
      //   localCreativity || creativity,
      //   songPart,
      //   localMood || mood,
      // )
      // queryClient.invalidateQueries(['usage'])
      setSuggestionsLoading(false)
      // setSuggestionsData(data)
    },
    [lines, creativity, mood, suggestionsData],
  )

  return (
    <LyricsContext.Provider
      value={{
        mode,
        setMode,
        assistantOpen,
        setAssistantOpen,
        aiModeActive,
        setAiModeActive,
        notEnoughLines,
        creativity,
        setCreativity,
        mood,
        setMood,
        suggestionsData,
        suggestionsLoading,
        fetchSuggestions,
        setSuggestionsData,
      }}
    >
      {children}
    </LyricsContext.Provider>
  )
}

export const useLyrics = () => React.useContext<FetchType>(LyricsContext)
