import classNames from 'classnames'
import React, { useRef } from 'react'
import { createPortal } from 'react-dom'

import ArrowIcon from '../../../../assets/icons/arrow.svg'
import useClickOutsideAlerter, { clickOutsideHookIds } from '../../../../hooks/clickOutsideAlerterHook'
import useSizes from '../../../../hooks/useSizes'
import IconMenuButton from '../../../common/IconMenuButton'
import Tooltip from '../../../common/Tooltip/Tooltip'
import styles from './SettingsPicker.module.scss'

type Props = {
  type?: 'icon' | 'iconText'
  side?: 'left' | 'right' | 'top' | 'bottom'
  value?: string
  icon?: JSX.Element | string
  iconPlaceholder?: JSX.Element
  options: { label: string; icon?: JSX.Element | string; value: string; color?: string }[]
  label: string
  id: string
  tooltip?: string
  offset?: number
  className?: string
  triggerClassName?: string
  onChange: (a?: string) => void
  open?: boolean
  onOpen: (a: boolean) => void
}

const SettingsPicker: React.FC<Props> = ({
  type = 'icon',
  side = 'right',
  value,
  icon,
  iconPlaceholder,
  options,
  label,
  tooltip,
  offset,
  className,
  open = false,
  onOpen,
  id,
  onChange,
  triggerClassName,
}) => {
  const { isMobile } = useSizes()

  const containerRef = useRef<HTMLDivElement>(null)

  useClickOutsideAlerter(containerRef, () => onOpen(false), clickOutsideHookIds.settingsPicker)

  const selectedLabel = options.find((opt) => opt.value === value)

  const generateText = (open: boolean, type: 'icon' | 'iconText') => {
    if (open) {
      if (type === 'iconText') {
        return (
          <>
            <div className={styles.selectPickerIcon} style={{ background: selectedLabel?.color }}>
              {selectedLabel?.icon || iconPlaceholder}
            </div>
            <div className={styles.selectPickerContent}>
              {label && <label className={styles.selectLabel}>{label}</label>}
              {selectedLabel?.label ? <span>{selectedLabel?.label}</span> : null}
            </div>
            {options?.length > 1 && <ArrowIcon data-rotated={open} />}
          </>
        )
      }

      return <span>{iconPlaceholder || icon}</span>
    }

    if (type === 'iconText') {
      return (
        <>
          <div className={styles.selectPickerIcon} style={{ background: selectedLabel?.color }}>
            {selectedLabel?.icon || iconPlaceholder}
          </div>
          <div className={styles.selectPickerContent}>
            {label && <label className={styles.selectLabel}>{label}</label>}
            {selectedLabel?.label ? <span>{selectedLabel?.label}</span> : null}
          </div>
          {options?.length > 1 && <ArrowIcon data-rotated={open} />}
        </>
      )
    }

    return <span>{icon || iconPlaceholder}</span>
  }

  return (
    <>
      {!isMobile && type !== 'iconText' && <Tooltip id={id} text={tooltip} />}
      <IconMenuButton
        type={type}
        dataTooltipId={id}
        text={generateText(open, type)}
        className={classNames(
          { [styles.triggerSelect]: type === 'iconText' && options?.length > 1 },
          {
            [styles.triggerSelect]: type === 'iconText' && options?.length <= 1,
            [styles.noOpen]: type === 'iconText' && options?.length <= 1,
          },
          { [styles.triggerButton]: type !== 'iconText' },
          triggerClassName,
        )}
        onClick={() => onOpen(!open)}
      />
      {open &&
        createPortal(
          <div className={classNames(styles.menu, className, { [styles.iconText]: type === 'iconText' })}>
            <div className={classNames(styles.bg, styles[side])} />
            {/* @ts-ignore */}
            <div
              className={styles.content}
              ref={containerRef}
              style={type === 'icon' ? { right: 16 + (offset || 0) } : { left: 20, alignItems: 'flex-start' }}
            >
              <span className={styles.label}>{label}</span>
              {options.map((opt) => (
                <div
                  onClick={() => {
                    onChange(opt.value === value && type !== 'iconText' ? undefined : opt.value)
                    onOpen(false)
                  }}
                  key={opt.value}
                  data-selected={opt.value === value}
                  className={styles.option}
                >
                  {opt.icon || null} {opt.label}
                </div>
              ))}
            </div>
          </div>,
          document.getElementById('lyrics-assistant-menu-container') as HTMLDivElement,
        )}
    </>
  )
}

export default SettingsPicker
