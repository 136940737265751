import Bulb2 from '../../../assets/icons/bulb2.svg'
import Bulb3 from '../../../assets/icons/bulb3.svg'
import Bulb from '../../../assets/icons/bulb.svg'
import { LyricsCreativity, LyricsMood } from '../../../utils/types'

export type LyricsAssistantMode = 'rhymes' | 'ai-lines' | 'synonyms' | 'thesaurus'

export const LyricsMoodToIcon: { [key in LyricsMood]: string } = {
  'In Love': '❤️',
  Fun: '😂',
  Anger: '😡',
  Happy: '😃',
  Sad: '😔️',
  Heartbroken: '💔',
}

export const LyricsCreativityToIcon: { [key in LyricsCreativity]: string } = {
  low: Bulb3,
  medium: Bulb2,
  high: Bulb,
}
