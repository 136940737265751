import classNames from 'classnames'
import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'

import { getUserUsageInnerRoute } from '../../../../../api/auth'
import CheckIcon from '../../../../../assets/icons/check.svg'
import GenerateLyricsInnerIcon from '../../../../../assets/icons/generate-lyrics-stars-inner.svg'
import RegenerateIcon from '../../../../../assets/icons/regenerate.svg'
import SmileIcon from '../../../../../assets/icons/smile.svg'
import { useCurrentUser } from '../../../../../context/CurrentUserContext'
import { useGeneratorModals } from '../../../../../context/GeneratorModalsContext'
import { useInternationalization } from '../../../../../context/InternationalizationContext'
import { useLyrics } from '../../../../../context/LyricsContext'
import { LimitTypesLabel } from '../../../../../utils/limits'
import { capitalize } from '../../../../../utils/stringUtils'
import { LyricsCreativity } from '../../../../../utils/types'
import BlurModal from '../../../../common/BlurModal/BlurModal'
import CircleLoader from '../../../../common/CircleLoader/CircleLoader'
import { usePlayerConfigState } from '../../../hooks/usePlayerConfigState'
import { LyricsCreativityToIcon, LyricsMoodToIcon } from '../../Lyrics.constants'
import SettingsPicker from '../../SettingsPicker/SettingsPicker'
import styles from './AILyricsModal.module.scss'

type Props = {
  isOpen: boolean
  onClose: () => void
}

type SongPartType = 'Verse' | 'Chorus' | 'Bridge'

export const AILyricsContent = forwardRef(({ isOpen, onClose }: Props, ref: any) => {
  const { text } = useInternationalization()
  const { currentUser } = useCurrentUser()
  const generatorModalsContext = useGeneratorModals()
  const { data: usage, isLoading } = useQuery({
    queryKey: ['usage'],
    queryFn: getUserUsageInnerRoute,
  })
  const { limit, current } = useMemo(() => usage?.lyrics_suggestions || { limit: -1, current: -1 }, [usage])
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()
  const { setMood, setCreativity, mood, creativity, fetchSuggestions, suggestionsData, suggestionsLoading } =
    useLyrics()

  const STRINGS = text.sidebars.lyrics.aiLyrics

  const [songPart, setSongPart] = useState<SongPartType>(STRINGS.songParts[0])
  const [settingsOpen, setSettingsOpen] = useState<undefined | 'creativity' | 'mood' | 'mode'>(undefined)

  const isLocked = current >= limit && !suggestionsData?.suggestion?.length

  useEffect(() => {
    if (!suggestionsData && isOpen && currentUser) fetchSuggestions(songPart)
  }, [isOpen])

  const handleRegenerate = () => {
    if (!isLocked) fetchSuggestions(songPart)
  }

  const handleUseIt = () => {
    const data = suggestionsData?.suggestion
    if (data && data.length) {
      playerConfigSetter.setLyrics({
        ...playerConfig.lyrics,
        lines: [...playerConfig.lyrics.lines, ...data],
      })
      onClose()
    }
  }

  const CreativityIcon = LyricsCreativityToIcon[creativity]

  return (
    <div className={classNames(styles.wrapper)}>
      <div className={styles.header}>
        <div className={styles.top}>
          <div className={styles.title}>{STRINGS.title}</div>

          <div className={styles.settings}>
            <div id='lyrics-assistant-menu-container' className={styles.blurMenu} />
            <SettingsPicker
              value={creativity}
              triggerClassName={styles.aiModeSelect}
              id='creativity'
              offset={50}
              icon={<CreativityIcon />}
              tooltip={`${STRINGS.settings.creativity.tooltip} ${capitalize(creativity)}`}
              label={STRINGS.settings.creativity.label}
              onChange={(a) => {
                if (!a) return
                setCreativity(a as LyricsCreativity)
                fetchSuggestions(songPart, { localCreativity: a as LyricsCreativity })
              }}
              options={STRINGS.settings.creativity.options}
              open={settingsOpen === 'creativity'}
              onOpen={(open: boolean) => setSettingsOpen(open ? 'creativity' : undefined)}
            />
            <SettingsPicker
              value={mood}
              triggerClassName={styles.aiModeSelect}
              id='mood'
              tooltip={mood ? `${STRINGS.settings.mood.tooltipChosen} ${mood}` : STRINGS.settings.mood.tooltipEmpty}
              iconPlaceholder={<SmileIcon />}
              icon={mood ? LyricsMoodToIcon[mood] : undefined}
              options={Object.values(STRINGS.settings.mood.options).map((mood: any) => ({
                label: mood,
                value: mood,
                icon: LyricsMoodToIcon[mood],
              }))}
              label={STRINGS.settings.mood.label}
              onChange={(v) => {
                setMood(v)
                fetchSuggestions(songPart, { localMood: v })
              }}
              open={settingsOpen === 'mood'}
              onOpen={(open: boolean) => setSettingsOpen(open ? 'mood' : undefined)}
            />
          </div>
        </div>

        <div className={styles.bottom}>
          <div className={styles.text}>{STRINGS.songPart}</div>

          {STRINGS.songParts.map((part: string) => (
            <div
              key={part}
              className={classNames(styles.btn, { [styles.active]: songPart === part })}
              onClick={() => {
                if (suggestionsLoading || part === songPart) return
                setSongPart(part as SongPartType)
                fetchSuggestions(part)
              }}
            >
              {part}
            </div>
          ))}
        </div>
      </div>

      <div className={styles.content}>
        {suggestionsLoading ? (
          [1, 1, 1, 1].map((_, index) => <div className={styles.skeleton} key={index} />)
        ) : (
          <>
            {suggestionsData?.suggestion?.length ? <p className={styles.title}>{songPart}:</p> : null}

            {suggestionsData?.suggestion?.map((row, index) => (
              <p key={`suggestion_row_${index}`}>{row}</p>
            ))}
          </>
        )}
      </div>

      <div className={styles.btnsContainer}>
        <div
          className={classNames(styles.btn, styles.primary, { [styles.active]: limit !== current })}
          onClick={handleRegenerate}
        >
          <GenerateLyricsInnerIcon className={styles.stars} />
          <RegenerateIcon className={styles.regenerate} />
          <div className={styles.btnInner}>
            <span>{STRINGS.regenerate}</span>
            <span className={styles.subInfo}>
              {current}/{limit}
            </span>
          </div>
        </div>
        <div
          className={classNames(styles.btn, { [styles.active]: suggestionsData?.suggestion?.length })}
          onClick={handleUseIt}
        >
          <CheckIcon className={styles.useIt} />
          {STRINGS.use}
        </div>
      </div>

      {isLocked ? (
        <>
          <div className={classNames(styles.blurMenu, styles.force)}>
            {isLoading ? (
              <CircleLoader />
            ) : (
              <BlurModal
                title={LimitTypesLabel.lyricsSuggestions}
                onClick={() => {
                  generatorModalsContext.setIsAILyricsOpen(false)
                }}
                upgradeText={STRINGS.upgrade}
                loginText={STRINGS.login}
              />
            )}
          </div>
        </>
      ) : null}
    </div>
  )
})

export default AILyricsContent
